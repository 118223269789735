import { EChartsOption } from 'echarts';

import { HDMUI_THEMES } from '@heidelberg/hdmui-angular';

import { getFormattedStringTime, timeFormatter } from '@data-terminal/utils';
import { ActivityState } from '@data-terminal/shared-models';

interface TimeIndicatorConfig {
    graphColor: string;
    labelColor: string;
    statusColor: string;
    maxValue: number;
    currentValue: number;
    currentTime: number;
}

export function getGraphConfig(
    status: string,
    currentTime: number,
    plannedTime: number,
    theme: HDMUI_THEMES | ''
): TimeIndicatorConfig {
    const HIGHLIGHT_COLOR = '#00B8F1';
    const TEXT_COLOR = theme === 'theme-light' ? '#60656B' : '#ffffff';
    const ORANGE_COLOR = '#ff7b00';
    const SUCCESS_COLOR = '#009b3c';

    let graphColor = HIGHLIGHT_COLOR;
    let labelColor = TEXT_COLOR;
    let statusColor = TEXT_COLOR;
    let maxValue = 100;
    let currentValue = 100;

    if (plannedTime !== 0) {
        graphColor = currentTime > plannedTime ? ORANGE_COLOR : HIGHLIGHT_COLOR;
        labelColor = currentTime > plannedTime ? ORANGE_COLOR : TEXT_COLOR;
        maxValue = plannedTime;
        currentValue = currentTime > plannedTime ? plannedTime : plannedTime - currentTime;
        currentTime = plannedTime - currentTime;
    }

    if (status === ActivityState.COMPLETED) {
        graphColor = SUCCESS_COLOR;
        statusColor = SUCCESS_COLOR;
        labelColor = TEXT_COLOR;
    }

    if (status === ActivityState.SUSPENDED || status === ActivityState.PAUSED) {
        statusColor = HIGHLIGHT_COLOR;
        labelColor = TEXT_COLOR;
    }

    return {
        graphColor,
        labelColor,
        maxValue,
        currentValue,
        currentTime,
        statusColor,
    };
}

export interface EchartTimeIndicatorTranslations {
    totalTime: string;
    time: string;
    operationState: string | undefined;
}

export function createOptions(
    status: string,
    translations: EchartTimeIndicatorTranslations,
    currentTime: number,
    plannedTime: number,
    theme: HDMUI_THEMES | ''
): EChartsOption {
    const settings = getGraphConfig(status, currentTime, plannedTime, theme);
    return {
        grid: {
            width: 100,
        },
        series: [
            {
                type: 'gauge',
                startAngle: 90,
                endAngle: -270,
                min: 0,
                max: settings.maxValue,
                splitNumber: 6,
                itemStyle: {
                    color: settings.graphColor,
                },
                progress: {
                    show: true,
                    width: 4,
                },
                pointer: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        width: 4,
                        color: [[1, '#dddddd']],
                    },
                },
                axisTick: {
                    splitNumber: 2,
                    lineStyle: {
                        width: 0,
                        color: settings.graphColor,
                    },
                },
                splitLine: {
                    length: 12,
                    lineStyle: {
                        width: 0,
                        color: 'rgba(96,101,107,0.25)',
                    },
                },
                axisLabel: {
                    show: false,
                },
                title: {
                    show: true,
                    position: 'bottom',
                    verticalAlign: 'bottom',
                },
                detail: {
                    borderColor: '#999',
                    borderWidth: 0,
                    width: '60%',
                    lineHeight: 40,
                    height: 40,
                    borderRadius: 8,
                    offsetCenter: [0, -25],
                    valueAnimation: true,
                    formatter: () => {
                        return timeIndicatorFormatter(settings.currentTime, plannedTime, status, translations);
                    },
                    rich: {
                        status: {
                            fontSize: '12px',
                            color: settings.statusColor,
                            opacity: 1,
                            fontFamily: 'Heidelberg Gothic Ml',
                            padding: [0, 0, 0, 0],
                        },
                        time: {
                            fontSize: '20px',
                            color: settings.labelColor,
                            opacity: 1,
                            fontFamily: 'Heidelberg Antiqua ML',
                            padding: [0, 0, 0, 0],
                            lineHeight: 25,
                        },
                        clock: {
                            fontSize: '12px',
                            color: settings.labelColor,
                            opacity: 1,
                            fontFamily: 'Heidelberg Gothic Ml',
                            padding: [0, 0, 0, 0],
                        },
                    },
                },
                data: [
                    {
                        value: settings.currentValue,
                    },
                ],
            },
        ],
    };
}

function formatTime(currentTime: number): string {
    const obj = timeFormatter(Math.abs(currentTime));
    return `{time| ${getFormattedStringTime(obj, false)} }`;
}

function formatStatus(status: string): string {
    return `{status|${status}}`;
}

function formatLabel(status: string, plannedTime: number, translations: EchartTimeIndicatorTranslations): string {
    const s =
        status.includes(ActivityState.SETUP) || status.includes(ActivityState.IN_PROGRESS)
            ? translations.time
            : translations.totalTime;
    return plannedTime === 0 ? '' : `{clock|${s}}`;
}

export function timeIndicatorFormatter(
    currentTime: number,
    plannedTime: number,
    state: string,
    translations: EchartTimeIndicatorTranslations
): string {
    const status = formatStatus(translations.operationState || state);
    const time = formatTime(currentTime);
    const label = formatLabel(state, plannedTime, translations);
    return `${status}\n${time}\n${label}`;
}
