import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatError, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { Employee } from '../../../../../../../shared-models/src/lib/employee';
import { debounceTime, distinctUntilChanged, map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AsyncPipe } from '@angular/common';
import { HdmuiAvatarComponent } from '@heidelberg/hdmui-angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { RequestDeliveryForm } from '../../../../models/request-delivery.model';
import { orgMemberEmailValidator } from './org-member-email.validator';

@UntilDestroy()
@Component({
    selector: 'data-terminal-request-delivery-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatError,
        MatInputModule,
        NgxMaskDirective,
        TranslateModule,
        MatButton,
        MatDivider,
        AsyncPipe,
        HdmuiAvatarComponent,
        MatAutocompleteModule,
        MatTooltipModule,
        MatGridListModule,
    ],
    templateUrl: './request-delivery-form.component.html',
    styleUrl: './request-delivery-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestDeliveryFormComponent implements OnInit, OnChanges, AfterViewInit {
    readonly #TIMEOUT_MILISECONDS = 50;
    readonly #DEBOUNCE_TIME_MILISECONDS = 500;

    @Input() requestDeliveryForm!: FormGroup<RequestDeliveryForm>;
    @Input() allOrgEmployees: Employee[] | null | undefined;

    @ViewChild('minutesInput', { read: ElementRef }) minutesInput!: ElementRef<HTMLInputElement>;

    employeeSuggestions$!: Observable<Employee[] | undefined>;

    ngOnInit(): void {
        this.listenForEmployeeSuggestions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.requestDeliveryForm && changes.allOrgEmployees) {
            this.requestDeliveryForm?.controls.operatorEmail.setValidators([
                orgMemberEmailValidator(this.allOrgEmployees),
            ]);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.minutesInput.nativeElement.select(), this.#TIMEOUT_MILISECONDS);
    }

    private listenForEmployeeSuggestions(): void {
        const operatorEmailFormControl: FormControl = this.requestDeliveryForm?.get('operatorEmail') as FormControl;

        if (operatorEmailFormControl) {
            this.employeeSuggestions$ = operatorEmailFormControl.valueChanges.pipe(
                untilDestroyed(this),
                debounceTime(this.#DEBOUNCE_TIME_MILISECONDS),
                distinctUntilChanged(),
                map((employeeInput: string) =>
                    this.allOrgEmployees?.filter((employee) =>
                        [employee.userId, employee.firstName, employee.lastName].some((option) =>
                            option.toLowerCase().includes(employeeInput.toLowerCase().trim())
                        )
                    )
                )
            );
        }
    }
}
