import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { Employee, UserLoginTimestamps } from 'projects/shared-models/src/lib/employee';
import { Observable, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EmployeeApiService {
    private readonly LOGIN_LIST_URL: (userId: string) => string = (userId) =>
        `${this.backendUrl}${this.orgRequestPrefix}getUserMachineLogins/${userId}`;

    private readonly EMPLOYEE_LIST_URL: string = `${this.backendUrl}${this.orgRequestPrefix}persons`;

    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public getUserMachineLogins(userId: string): Observable<UserLoginTimestamps[]> {
        //TODO Interface
        return this.http.get<UserLoginTimestamps[]>(this.LOGIN_LIST_URL(userId));
    }

    public getEmployees(): Observable<Employee[]> {
        return this.http.get<Employee[]>(this.EMPLOYEE_LIST_URL).pipe(take(1));
    }
}
