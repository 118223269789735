import { Component, EventEmitter, Inject, Input, NgModule, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Buffer } from 'buffer';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import {
    CloudToolbarBreadcrumb,
    CloudToolbarUser,
    HdmuiAboutData,
    HDMUIAboutDialogService,
    HdmuiCloudToolbarModule,
    Notification,
    NotificationPriority,
} from '@heidelberg/hdmui-angular';
import { NotificationService } from '@heidelberg/control-center-frontend-integration';
import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import {
    AppConfigService,
    getCurrentPageTitle,
    IS_PRODUCTION,
    isSecondLevel,
    NavigationService,
} from '@data-terminal/data-access';
import { BreadcrumbsService } from '@data-terminal/utils';
import { RequestType, ROUTE_PATH } from '@data-terminal/shared-models';

import { ErrorDialogComponent } from '../error-dialog/error-dialog/error-dialog.component';
import { ErrorNotification } from 'projects/data-access/src/lib/notifications/notification.model';
import { NotificationErrorItem } from 'projects/shared-models/src/lib/notification.';
import { BE_VERSION, FE_VERSION, UPDATE_TIMESTAMP } from '../../../../src/app/generated/versioningTimestamps';

const INDEX_0 = 0;
const INDEX_1 = 1;
const INDEX_2 = 2;
const INDEX_3 = 3;

@UntilDestroy()
@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements OnInit {
    @Input() public cloudToolbarUser!: CloudToolbarUser;
    @Output() public logoutAction = new EventEmitter<void>();

    notifications: Notification[] = [];

    public controlCenterStage: 'stage' | undefined;
    public appStage: string | undefined;
    public appClientId = 'data-terminal';
    public orgId: string;
    public currentPageTitle$!: Observable<string>;
    public isSecondLevel$!: Observable<boolean>;
    public breadcrumbs$!: Observable<CloudToolbarBreadcrumb[]>;

    constructor(
        @Inject(IS_PRODUCTION) public readonly isProduction: boolean,
        private readonly navigationService: NavigationService,
        private readonly breadcrumbsService: BreadcrumbsService,
        private readonly ccAuthService: CCAuthService,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly aboutDialogService: HDMUIAboutDialogService,
        private readonly translate: TranslateService,
        private readonly appConfigService: AppConfigService
    ) {
        this.breadcrumbs$ = this.breadcrumbsService.breadcrumbs$;
        this.controlCenterStage = this.isProduction ? undefined : 'stage';
        this.orgId = this.ccAuthService.getCurrentMembership()?.organizationId || '';
        this.appStage = this.isProduction ? undefined : appConfigService.getStage();
    }

    public ngOnInit(): void {
        this.watchRouteChanges();
        if (this.orgId) {
            this.notificationService.setCurrentUser({ personId: this.cloudToolbarUser.id, organizationId: this.orgId });
            this.notificationService.notifications$.pipe(untilDestroyed(this)).subscribe((notifications) => {
                if (!this.areNotificationsEqual(this.notifications, notifications)) {
                    this.notifications = notifications;
                    this.checkForErrorNotifications(notifications);
                }
            });
        }
    }

    private getHDMUIAboutData(): HdmuiAboutData {
        return {
            appTitle: this.translate.instant('DC.APP.TITLE'),
            appCategory: this.translate.instant('*.appCategory.production'),
            timestamp: {
                text: this.translate.instant('*.lastUpdateOn'),
                value: UPDATE_TIMESTAMP,
            },
            licenses: {
                text: this.translate.instant('*.thirdPartyLicences'),
                path: './assets/generated/3rdpartylicences.txt',
            },
            versions: [
                {
                    text: this.translate.instant('*.versionUi'),
                    value: FE_VERSION,
                },
                {
                    text: this.translate.instant('*.versionService'),
                    value: BE_VERSION,
                },
            ],
        };
    }

    openErrorDialog(errorCode: string, requestType: RequestType, machineId: string, errorMessage: string): void {
        this.dialog.open(ErrorDialogComponent, {
            disableClose: true,
            data: { errorCode, requestType, machineId, errorMessage },
        });
    }

    public doLogout(): void {
        this.logoutAction.emit();
    }

    public goBack(): void {
        this.navigationService.goToParentRoute();
    }

    public onHelpClick(): void {
        throw Error('not implemented yet');
    }

    private watchRouteChanges(): void {
        this.isSecondLevel$ = this.navigationService.navData$.pipe(isSecondLevel);
        this.currentPageTitle$ = this.navigationService.navData$.pipe(getCurrentPageTitle);
    }

    onNotificationRemove(notifications: Notification[]): void {
        if (notifications?.length === 1) {
            const notificationId = notifications[0].id;
            this.notificationService.delete(notificationId).subscribe(() => {
                this.notifications = this.notifications.filter((n) => n.id !== notificationId);
            });
        } else if (notifications?.length > 1) {
            this.notificationService.deleteAll().subscribe(() => {
                this.notifications = [];
            });
        }
    }

    public onNotificationsSettingsClick(): void {
        const orgId = this.ccAuthService.getCurrentMembership()?.organizationId;
        this.router.navigateByUrl(`${orgId}/${ROUTE_PATH.notifications}`);
    }

    private areNotificationsEqual(a: Notification[], b: Notification[]): boolean {
        if (a.length === 0 && b.length === 0) {
            return true;
        }

        if (a.length === 0 || b.length === 0) {
            return false;
        }

        return a[0].id === b[0].id;
    }

    public onAboutClick(): void {
        this.aboutDialogService.open(this.getHDMUIAboutData());
    }

    checkForErrorNotifications(notifications: Notification[]): void {
        const errorNotification = notifications.find(
            (notification) => notification.priority === NotificationPriority.CRITICAL_ERROR
        );
        if (errorNotification) {
            const errorBody = errorNotification.body.split(';');
            const errorMessage = ToolbarComponent.base64ToJson(errorBody[INDEX_3]) as ErrorNotification;
            const requestType = errorBody[INDEX_1] as RequestType;
            const machine = errorBody[INDEX_2];
            this.openErrorDialog(errorBody[INDEX_0], requestType, machine, JSON.stringify(errorMessage));
            this.notificationService.delete(errorNotification.id).subscribe({});
        }
    }

    private static base64ToJson(base64String: string): NotificationErrorItem {
        const json = Buffer.from(base64String, 'hex').toString();
        return JSON.parse(json);
    }
}

@NgModule({
    imports: [HdmuiCloudToolbarModule, CommonModule, TranslateModule, MatListModule, MatIconModule, MatTooltipModule],
    declarations: [ToolbarComponent],
    exports: [ToolbarComponent],
})
export class ToolbarComponentModule {}
