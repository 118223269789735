import { InjectionToken } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { RequestMetadata } from '@data-terminal/utils';
import { Activity, UserMachines } from '@data-terminal/shared-models';
import { Employee } from '../../../../shared-models/src/lib/employee';

export enum GlobalStateSelectors {
    USER_MACHINES = 'userMachines',
    OTHER_ACTIVITIES = 'otherActivities',
    All_EMPLOYEES = 'allEmployees',
}

export interface GlobalState {
    [GlobalStateSelectors.USER_MACHINES]: RequestMetadata<UserMachines>;
    [GlobalStateSelectors.OTHER_ACTIVITIES]: RequestMetadata<Activity[]>;
    [GlobalStateSelectors.All_EMPLOYEES]: Employee[];
}

export const GLOBAL_RX_STATE = new InjectionToken<RxState<GlobalState>>('GLOBAL_RX_STATE');
