import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { RequestDeliveryBody } from '../../models/request-delivery.model';

@Injectable({
    providedIn: 'root',
})
export class TaskPilotApiService {
    readonly #httpClient = inject(HttpClient);
    readonly #backendUrl = inject(BACKEND_URL);
    readonly #orgRequestPrefix = inject(ORGANIZATION_REQUEST_PREFIX);

    readonly #createTaskUrl = `${this.#backendUrl}${this.#orgRequestPrefix}createTask`;

    public requestDelivery(body: RequestDeliveryBody): Observable<boolean> {
        return this.#httpClient.post<boolean>(this.#createTaskUrl, body);
    }
}
