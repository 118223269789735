import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'translateOperationName',
    standalone: true,
})
export class TranslateOperationName implements PipeTransform {
    readonly #translateService = inject(TranslateService);

    readonly #UNPLANNED_WORK = 'UnplannedWork';
    readonly #UNPLANNED_WORK_TRANSLATION = this.#translateService.instant('AP_UNSCHEDULED_OPERATION');

    transform(name: string): string {
        if (name.trim() === this.#UNPLANNED_WORK) {
            return this.#UNPLANNED_WORK_TRANSLATION;
        }

        return name.length > 0 ? name : '-';
    }
}
