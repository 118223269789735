import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BarcodeScanDialogComponent, BarcodeScanDialogData, BarcodeScanInputType } from '../dialogs';

@Injectable({
    providedIn: 'root',
})
export class BarcodeScanDialogService {
    readonly #dialog = inject(MatDialog);

    public openBarcodeScanDialog(
        type: BarcodeScanInputType
    ): MatDialogRef<BarcodeScanDialogComponent, string | undefined> {
        return this.#dialog.open<BarcodeScanDialogComponent, BarcodeScanDialogData, string | undefined>(
            BarcodeScanDialogComponent,
            {
                disableClose: true,
                data: {
                    inputType: type,
                },
            }
        );
    }
}
