import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { ImperialUnits, MetricUnits, UnitType } from '@data-terminal/shared-models';

const MM_TO_INCH = 25.4;
const MICROMETER_TO_MM = 1000;

@Pipe({
    name: 'unitFormatter',
})
export class UnitFormatterPipe implements PipeTransform {
    private readonly unitSystem?: string;

    constructor(private readonly ccAuthService: CCAuthService) {
        this.unitSystem = this.ccAuthService.getCurrentUser()?.unitSystem;
    }

    transform(value: string | number, targetUnitType: UnitType, decimals: null | number = null): string | number {
        let suffix = '';
        if (typeof value === 'string') {
            value = Number(value);
        }

        if (this.unitSystem === 'imperial') {
            if (targetUnitType === UnitType.LENGTH) {
                value = value / MICROMETER_TO_MM / MM_TO_INCH;
                suffix = ' ' + ImperialUnits.LENGTH;
            }
            if (targetUnitType === UnitType.GRAMMAGE) {
                suffix = ' ' + ImperialUnits.GRAMMAGE;
            }
        }
        if (this.unitSystem === 'metric') {
            if (targetUnitType === UnitType.LENGTH) {
                value = value / MICROMETER_TO_MM;
                suffix = ' ' + MetricUnits.LENGTH;
            }
            if (targetUnitType === UnitType.GRAMMAGE) {
                suffix = ' ' + MetricUnits.GRAMMAGE;
            }
        }

        if (decimals !== null) {
            if (value % 1 > 0) {
                value = value.toFixed(decimals);
            }
        }

        return value.toString() + suffix;
    }
}

@NgModule({
    imports: [],
    declarations: [UnitFormatterPipe],
    exports: [UnitFormatterPipe],
})
export class UnitFormatterPipeModule {}
