import { Employee } from '../../../../../../../shared-models/src/lib/employee';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { neitherNullNorUndefined } from '../../../../../../../utils/src/lib/helpers/neither-null-nor-undefined';

export const orgMemberEmailValidator = (employees: Employee[] | null | undefined): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        return neitherNullNorUndefined(employees) &&
            employees?.map((employee) => employee.userId.toLowerCase()).includes(control.value?.toLowerCase())
            ? null
            : { emailNotCorrect: true };
    };
};
