import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    NgZone,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BarcodeFormat, Result } from '@zxing/library';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';

import {
    HdmuiBaseDialogComponent,
    HdmuiComponentsModule,
    HdmuiEmptyStatesModule,
    HdmuiIconsModule,
} from '@heidelberg/hdmui-angular';

import { ResizeService } from '@data-terminal/utils';
import { LoadingIndicatorComponentModule } from '@data-terminal/ui-presentational';

const OVERLAY_SIDE_SIZE = 0.8;

export enum BarcodeScanInputType {
    SEARCH,
    REGULAR,
}

export interface BarcodeScanDialogData {
    inputType: BarcodeScanInputType;
}

@UntilDestroy()
@Component({
    standalone: true,
    styleUrls: ['barcode-scan-dialog.component.scss'],
    templateUrl: 'barcode-scan-dialog.component.html',
    imports: [
        MatButtonModule,
        HdmuiIconsModule,
        MatIconModule,
        ZXingScannerModule,
        HdmuiEmptyStatesModule,
        TranslateModule,
        HdmuiComponentsModule,
        MatButtonToggleModule,
        MatInputModule,
        LoadingIndicatorComponentModule,
    ],
})
export class BarcodeScanDialogComponent implements AfterViewInit, OnDestroy {
    readonly #dialogRef = inject(MatDialogRef<BarcodeScanDialogComponent, string | undefined>);
    readonly #data: BarcodeScanDialogData = inject(MAT_DIALOG_DATA);
    readonly #snackBar = inject(MatSnackBar);
    readonly #translateService = inject(TranslateService);
    readonly #ngZone = inject(NgZone);
    readonly #resizeService = inject(ResizeService);

    readonly #resizeCallback: (entries: ResizeObserverEntry[]) => void = (entries) => {
        this.#ngZone.run(() => {
            const entry = entries.pop();
            const width = entry?.contentRect.width || 0;
            const height = entry?.contentRect.height || 0;
            const side = Math.min(width, height) * OVERLAY_SIDE_SIZE;
            this.overlay.nativeElement.style.width = side + 'px';
            this.overlay.nativeElement.style.height = side + 'px';
        });
    };

    readonly #scannerResizeObserver: ResizeObserver = this.#resizeService.getNewResizeObserver(this.#resizeCallback);

    public readonly OPTION_NO_BUTTON = HdmuiBaseDialogComponent.OPTION_NO_BUTTON;
    public readonly ALLOWED_FORMATS = [BarcodeFormat.CODE_128, BarcodeFormat.QR_CODE];

    public devices: MediaDeviceInfo[] = [];
    public selectedDevice?: MediaDeviceInfo;
    public isTorchCompatible = false;
    public isTorchEnabled = false;
    public isLoading = true;
    public hasCameraPermissions!: boolean;
    public inputType!: BarcodeScanInputType;

    @ViewChild('overlay')
    overlay!: ElementRef;

    @ViewChild('scanner')
    scanner!: ElementRef;

    @Output()
    manualActionClick = new EventEmitter<void>();

    constructor() {
        this.inputType = this.#data.inputType;
    }

    ngAfterViewInit(): void {
        this.#scannerResizeObserver.observe(this.scanner.nativeElement);
    }

    ngOnDestroy(): void {
        this.#scannerResizeObserver.unobserve(this.scanner.nativeElement);
    }

    public get scannerResizeObserver(): ResizeObserver {
        return this.#scannerResizeObserver;
    }

    public get flashlightIcon(): string {
        return this.isTorchEnabled ? 'hdmui:flashlightOn' : 'hdmui:flashlightOff';
    }

    public get emptyStatesTitle(): string {
        const translationKey = this.hasCameraPermissions
            ? 'DC.BARCODESCANDIALOG.NOCAMERAS.TITLE'
            : 'DC.BARCODESCANDIALOG.NOPERMISSIONS.TITLE';
        return this.#translateService.instant(translationKey);
    }

    public get emptyStatesDescription(): string {
        const translationKey = this.hasCameraPermissions
            ? 'DC.BARCODESCANDIALOG.NOCAMERAS.DESCRIPTION'
            : 'DC.BARCODESCANDIALOG.NOPERMISSIONS.DESCRIPTION';

        return this.#translateService.instant(translationKey, {
            buttonText: this.#translateService.instant('DC.ACTION.MANUALSEARCH'),
        });
    }

    public changeCamera(): void {
        const currentIndex = this.devices.findIndex((device) => device.deviceId === this.selectedDevice?.deviceId);
        const nextIndex = (currentIndex + 1) % this.devices.length;
        this.selectedDevice = this.devices[nextIndex];
    }

    public toggleFlashlight(): void {
        this.isTorchEnabled = !this.isTorchEnabled;
    }

    public onManualActionClick(): void {
        this.manualActionClick.emit();
        this.#dialogRef.close();
    }

    public onTorchCompatible(isTorchCompatible: boolean): void {
        this.isTorchCompatible = isTorchCompatible;
    }

    public camerasFoundHandler(devices: MediaDeviceInfo[]): void {
        this.devices = devices;
        this.isLoading = false;
    }

    public camerasNotFoundHandler(): void {
        this.isLoading = false;
    }

    public onPermissionsResponse(hasPermissions: boolean): void {
        this.hasCameraPermissions = hasPermissions;
        if (!hasPermissions) {
            this.isLoading = false;
        }
    }

    public scanSuccessHandler(): void {
        this.tryVibrate();
    }

    public scanErrorHandler(error: Error): void {
        this.#snackBar.open(
            this.#translateService.instant('DC.BARCODESCANDIALOG.ERROR', { error: error.message }),
            this.#translateService.instant('CLOSE'),
            {
                duration: 3000,
                panelClass: 'hdmui-error',
            }
        );
    }

    public scanCompleteHandler(result: Result): void {
        if (result) {
            this.#dialogRef.close(result.getText());
        }
    }

    private tryVibrate(): void {
        // https://developer.mozilla.org/en-US/docs/Web/API/Vibration_API
        const VAL_200 = 200;
        if (window && window.navigator && window.navigator.vibrate) {
            window.navigator.vibrate([VAL_200, 100, VAL_200]);
        }
    }

    protected readonly BarcodeScanDialogType = BarcodeScanInputType;
}
