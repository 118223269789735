<hdmui-cloud-toolbar
    #cloudToolbar
    [currentUser]="cloudToolbarUser"
    [appTitle]="(currentPageTitle$ | async)! | translate"
    [showSideBarButton]="true"
    [showSidenavSettingsButton]="true"
    (sidenavAboutClick)="onAboutClick()"
    [isSecondLevel]="(isSecondLevel$ | async)!"
    [appId]="appClientId"
    [ccStage]="controlCenterStage"
    [appStage]="appStage"
    [breadcrumbs]="breadcrumbs$ | async"
    (backButtonClick)="goBack()"
    (logoutClick)="doLogout()"
    [sidenavSettingsRouterLink]="[orgId, 'settings']"
    [showNotificationButton]="true"
    [notifications]="notifications"
    [appCategory]="'*.appCategory.production' | translate"
    [appIcon]="'assets/icons/HDM-Data-Capture-Nearlywhite.svg'"
    (notificationsRemove)="onNotificationRemove($event)"
>
    <div sidenavContent>
        <mat-nav-list>
            <mat-list-item
                (click)="onNotificationsSettingsClick(); cloudToolbar.sidenav?.close()"
                [matTooltip]="'NOTIFICATIONS_SETTINGS.TITLE' | translate"
            >
                <mat-icon matListItemIcon svgIcon="hdmui:bell"></mat-icon>
                {{ 'NOTIFICATIONS_SETTINGS.TITLE' | translate }}
            </mat-list-item>
        </mat-nav-list>
    </div>
</hdmui-cloud-toolbar>
