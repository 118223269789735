@if (
    {
        machineSignInTimer: machineSignInTimer$ | async,
        inactiveWorkstations: inactiveWorkstations$ | async,
        inactivityTimer: inactivityTimer$ | async,
    };
    as asyncData
) {
    <mat-toolbar class="footer__container">
        <button mat-icon-button (click)="onLeaveButtonClick()">
            <mat-icon role="img" svgIcon="hdmui:exit"></mat-icon>
        </button>
        <mat-divider></mat-divider>
        @if (user) {
            <div class="footer__data">
                @if (isTablet) {
                    <div class="footer__data--row">
                        <ng-container *ngTemplateOutlet="userMail"></ng-container>
                        @if (asyncData.machineSignInTimer) {
                            <span class="hdmui-mx-3">-</span>
                            <ng-container *ngTemplateOutlet="loggedInSince"></ng-container>
                        }
                        @if (user.userMachines && user.userMachines.length > 0) {
                            <span class="hdmui-mx-3">-</span>
                            <ng-container *ngTemplateOutlet="machineCount"></ng-container>
                        }
                    </div>
                    <div class="footer__data--row">
                        @if (asyncData.inactiveWorkstations?.length) {
                            <ng-container *ngTemplateOutlet="noActivity"></ng-container>
                        }
                    </div>
                } @else {
                    <ng-container *ngTemplateOutlet="userMail"></ng-container>
                    <div class="footer__data--row">
                        @if (asyncData.machineSignInTimer) {
                            <ng-container *ngTemplateOutlet="loggedInSince"></ng-container>
                            <span class="hdmui-mx-3">-</span>
                        }
                        @if (user.userMachines && user.userMachines.length > 0) {
                            <ng-container *ngTemplateOutlet="machineCount"></ng-container>
                        }
                        @if (asyncData.inactiveWorkstations?.length) {
                            <span class="hdmui-mx-3">-</span>
                            <ng-container *ngTemplateOutlet="noActivity"></ng-container>
                        }
                    </div>
                }
            </div>
        }
        <div class="footer__timecounter">
            <ng-container *ngTemplateOutlet="timeCounter"></ng-container>
        </div>
    </mat-toolbar>

    <ng-template #loggedInSince>
        <p class="hdmui-caption hdmui-m-0 hdmui-p-0">
            {{
                'DC.LOGGED_IN_SINCE'
                    | translate: { time: asyncData.machineSignInTimer || 0 | timeFormatter: true : true : true : false }
            }}
        </p>
    </ng-template>

    <ng-template #machineCount>
        <p class="hdmui-caption hdmui-m-0 hdmui-p-0">
            {{ 'DC.FOOTER.MACHINE_INFO' | translate: { machine: user.userMachines.length } }}
        </p>
    </ng-template>

    <ng-template #noActivity>
        <p class="footer__inactive-warning hdmui-caption hdmui-m-0 hdmui-p-0 mat-mdc-form-field-error">
            {{ 'DC.FOOTER.NO_ACTIVITY' | translate }}
        </p>
    </ng-template>

    <ng-template #timeCounter>
        @if (displayFooterTime) {
            <p class="footer__timecounter__value mat-mdc-form-field-error hdmui-caption hdmui-ms-3 hdmui-m-0 hdmui-p-0">
                {{ asyncData.inactivityTimer || 0 | timeFormatter }}
            </p>
        }
    </ng-template>

    <ng-template #userMail>
        <p class="hdmui-overline hdmui-m-0 hdmui-p-0">{{ user.userId }}</p>
    </ng-template>
}
