<form [formGroup]="requestDeliveryForm" class="hdmui-content request-delivery-form">
    <div class="request-delivery-form__time" formGroupName="targetTime">
        <mat-form-field>
            <mat-label>{{ 'DC.REQUEST_DELIVERY_DIALOG.ITEM.TARGET_TIME_H' | translate }}</mat-label>
            <input matInput formControlName="hours" mask="separator.0" />
            <span matSuffix>h</span>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'DC.REQUEST_DELIVERY_DIALOG.ITEM.TARGET_TIME_MIN' | translate }}</mat-label>
            <input #minutesInput matInput formControlName="minutes" mask="separator.0" />
            <span matSuffix>min</span>
        </mat-form-field>
    </div>
    @if (
        requestDeliveryForm.controls.targetTime.controls.hours.hasError('required') ||
        requestDeliveryForm.controls.targetTime.controls.minutes.hasError('required')
    ) {
        <div class="request-delivery-form__time--error">
            <mat-error>{{ '*.required' | translate }}</mat-error>
        </div>
    }
    <mat-form-field>
        <mat-label>{{ 'AMOUNT' | translate }}</mat-label>
        <input matInput formControlName="amount" mask="separator.0" />
        @if (requestDeliveryForm.controls.amount.hasError('min')) {
            <mat-error>{{ 'VALIDATION.MIN' | translate: { value: 0 } }}</mat-error>
        }
        @if (requestDeliveryForm.controls.amount.hasError('max')) {
            <mat-error
                >{{
                    'MM.APP.VALIDATION.MAX'
                        | translate: { value: requestDeliveryForm.controls.amount.getError('max')?.max }
                }}
            </mat-error>
        }
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'DC.REQUEST_DELIVERY_DIALOG.OPERATOR_EMAIL' | translate }}</mat-label>
        <input matInput formControlName="operatorEmail" [matAutocomplete]="auto" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            @for (suggestion of employeeSuggestions$ | async; track suggestion) {
                <mat-option [value]="suggestion.userId">
                    <hdmui-avatar
                        [fullName]="suggestion.userId"
                        [matTooltip]="suggestion.userId"
                        matTooltipPosition="above"
                        class="hdmui-avatar-small"
                    ></hdmui-avatar>
                    <div>
                        <p class="hdmui-m-0">{{ suggestion.firstName }} {{ suggestion.lastName }}</p>
                        <p class="hdmui-m-0 hdmui-font-small">{{ suggestion.userId }}</p>
                    </div>
                </mat-option>
            }
        </mat-autocomplete>
        @if (requestDeliveryForm.controls.operatorEmail.hasError('emailNotCorrect')) {
            <mat-error>{{ 'DC.REQUEST_DELIVERY_DIALOG.OPERATOR_EMAIL.VALIDATION' | translate }}</mat-error>
        }
    </mat-form-field>
</form>
