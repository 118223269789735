<hdmui-base-dialog
    [dialogOptions]="option"
    (cancelClicked)="closeDialog()"
    (okClicked)="confirmActivity()"
    [contentNoPadding]="true"
    [okText]="'*.action.confirm' | translate"
    [cancelText]="'*.action.cancel' | translate"
>
    @if (dialogData.isStopped) {
        <div class="m-5">
            {{ 'DC.ACTIVITY_CONFIRMATION.DIALOG.START_ACTIVITY.STOPPED' | translate }}
        </div>
    }
    @if (dialogData.isCleanup) {
        <div class="m-5">
            {{ 'DC.ACTIVITY_CONFIRMATION.DIALOG.START_ACTIVITY.CLEANUP' | translate }}
        </div>
    }
</hdmui-base-dialog>
