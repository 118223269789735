export interface Task {
    taskId: string;
    status: TaskStatus;
    endDate: number;
    taskType: TaskType;
    taskCreateType: TaskCreateType;
    itemType: ItemType;
    transmitDate?: number;
    targetQuantity?: number;
    currentQuantity?: number;
    employee?: string;
}

export enum TaskStatus {
    create = 'create',
    active = 'active',
    delete = 'delete',
    close_ok = 'close_ok',
    timeout = 'timeout',
    close_timeout = 'close_timeout',
}

export enum TaskType {
    input = 'input',
    output = 'output',
}

export enum TaskCreateType {
    manual = 'manual',
    automatic = 'automatic',
    new = 'new',
}

export enum ItemType {
    Paper = 'Paper',
    Plate = 'Plate',
    Ink = 'Ink',
    AniloxRoller = 'AniloxRoller',
    PlateTrolley = 'PlateTrolley',
    Tool = 'Tool',
    Semifinish = 'Semifinish',
    Others = 'Others',
    Consumables = 'Consumables',
    Varnish = 'Varnish',
    Coating = 'Coating',
    GeneralOperationInfo = 'GeneralOperationInfo',
}
