@if (operationDetails) {
    <div class="operation-details" [class.operation-details--tablet]="isTablet$ | async">
        @if (transfer2Cloud) {
            <div class="operation-details__column" [class.operation-details__column--single]="isTablet$ | async">
                <data-terminal-operation-details-preview
                    [operationDetails]="operationDetails"
                ></data-terminal-operation-details-preview>
            </div>
        }
        <div
            class="operation-details__column"
            [class.operation-details__column--single]="!transfer2Cloud || (isTablet$ | async)"
        >
            <data-terminal-operation-details-info
                [operationDetails]="operationDetails"
            ></data-terminal-operation-details-info>
        </div>
    </div>
}
