import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
    RequestDeliveryDialogData,
    RequestDeliveryForm,
    RequestDeliveryFormValue,
    RequestDeliveryItemType,
    RequestDeliveryPartialData,
} from '../../../../models/request-delivery.model';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { Observable } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { GLOBAL_RX_STATE, GlobalState, GlobalStateSelectors } from '@data-terminal/data-access';
import { Employee } from '../../../../../../../shared-models/src/lib/employee';
import { RequestDeliveryFormComponent } from '../request-delivery-form/request-delivery-form.component';
import { requireOneControl } from '@data-terminal/utils';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'data-terminal-request-delivery-dialog',
    standalone: true,
    imports: [TranslateModule, MatButton, MatIconModule, MatDivider, RequestDeliveryFormComponent, CommonModule],
    templateUrl: './request-delivery-dialog.component.html',
    styleUrl: './request-delivery-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestDeliveryDialogComponent implements OnInit {
    readonly #MAX_AMOUNT = 9999999999;
    readonly #MIN_MINUTES_AMOUNT = 15;

    readonly #dialogRef: MatDialogRef<RequestDeliveryDialogComponent> = inject(MatDialogRef);
    public readonly data: RequestDeliveryDialogData = inject(MAT_DIALOG_DATA);
    readonly #globalState: RxState<GlobalState> = inject(GLOBAL_RX_STATE);
    readonly employees$: Observable<Employee[]> = this.#globalState.select(GlobalStateSelectors.All_EMPLOYEES);

    requestDeliveryForm!: FormGroup<RequestDeliveryForm>;

    protected readonly RequestDeliveryItemType = RequestDeliveryItemType;

    ngOnInit(): void {
        this.initRequestDeliveryForm();
    }

    public cancel(): void {
        return this.#dialogRef.close();
    }

    public requestDelivery(): void {
        return this.#dialogRef.close(this.prepareRequestDeliveryData(this.requestDeliveryForm.value));
    }

    private initRequestDeliveryForm(): void {
        this.requestDeliveryForm = new FormGroup<RequestDeliveryForm>({
            targetTime: new FormGroup(
                {
                    hours: new FormControl<number | undefined>(undefined, {
                        nonNullable: true,
                    }),
                    minutes: new FormControl<number | undefined>(this.#MIN_MINUTES_AMOUNT, {
                        nonNullable: true,
                    }),
                },
                [requireOneControl('hours', 'minutes')]
            ),
            amount: new FormControl<number | undefined>(undefined, {
                nonNullable: true,
                validators: [Validators.min(0), Validators.max(this.#MAX_AMOUNT)],
            }),
            operatorEmail: new FormControl<string | undefined>(undefined, {
                nonNullable: true,
            }),
        });
    }

    private prepareRequestDeliveryData(requestDeliveryFormData: RequestDeliveryFormValue): RequestDeliveryPartialData {
        const { targetTime, amount, operatorEmail } = requestDeliveryFormData;
        let requestDeliveryData: RequestDeliveryPartialData = {
            ...(amount && { amount }),
            ...(operatorEmail && { operatorEmail }),
        };

        if (targetTime?.hours || targetTime?.minutes) {
            const hours = targetTime?.hours ? 60 * 60 * 1000 * targetTime?.hours : 0;
            const minutes = targetTime?.minutes ? 60 * 1000 * targetTime?.minutes : 0;

            const endDate = Date.now() + hours + minutes;

            requestDeliveryData = {
                ...requestDeliveryData,
                endDate,
            };
        }
        return requestDeliveryData;
    }
}
