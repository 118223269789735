import { Pipe, PipeTransform } from '@angular/core';

import { Operation } from '@data-terminal/shared-models';
import { TaskStatus } from '../../../../shared-models/src/lib/task';
import { isNullOrUndefined } from '@data-terminal/utils';

@Pipe({
    name: 'isRequestDeliveryPossible',
    standalone: true,
})
export class RequestDeliveryPossiblePipe implements PipeTransform {
    public transform(operation: Operation): boolean {
        return (
            isNullOrUndefined(operation.tasks) ||
            operation.tasks?.length === 0 ||
            operation.tasks?.some(
                (task) => task.status === TaskStatus.close_ok || task.status === TaskStatus.close_timeout
            )
        );
    }
}
