@if ((isProfileLoading$ | async) === true) {
    <data-terminal-sign-in-connect-indicator class="app-sign-in-and-loading-state">
    </data-terminal-sign-in-connect-indicator>
}

@if ((isLoading$ | async) === true) {
    <data-terminal-loading-indicator class="app-loading-state"></data-terminal-loading-indicator>
}

@if (initialLoadDone) {
    @if (hideBasicUIElements$ | async) {
        <router-outlet></router-outlet>
    } @else {
        @if (deviceTypeService.isMobile) {
            <hdmui-screen-blocker
                [icon]="'hdmui:exclamationMarkCircled'"
                [title]="'DC.SCREEN_BLOCKER.TITLE' | translate"
                [message]="'DC.SCREEN_BLOCKER.MESSAGE' | translate"
                [buttonText]="'DC.SCREEN_BLOCKER.BUTTON' | translate"
                (buttonClicked)="onScreenBlockerRedirect()"
                [showOnBreakpoints]="breakpoints"
            >
                <app-legal-line *dataTerminalFooterVisible></app-legal-line>
            </hdmui-screen-blocker>
        } @else {
            <div class="hdmui-top-structure">
                <header>
                    <app-toolbar
                        [cloudToolbarUser]="cloudUser"
                        (logoutAction)="onLogout()"
                        class="hdmui-level00"
                    ></app-toolbar>
                    @if (showNavigation$ | async) {
                        <app-navigation [tabPanel]="tabPanel" class="hdmui-subnav"></app-navigation>
                    }
                </header>
                <section class="hdmui-content hdmui-flex">
                    <mat-tab-nav-panel #tabPanel>
                        @if ((isRouterInLoadingState$ | async) === true && (isProfileLoading$ | async) === false) {
                            <data-terminal-loading-indicator class="app-center"></data-terminal-loading-indicator>
                        } @else {
                            <router-outlet></router-outlet>
                        }
                    </mat-tab-nav-panel>
                    <app-legal-line *dataTerminalFooterVisible></app-legal-line>
                </section>

                <footer>
                    <app-footer
                        [isTablet]="(isTablet$ | async) || false"
                        [activatedRoute]="activatedRoute"
                        [availableAssistants]="(userMachines$ | async)?.data?.availableAssistants || []"
                    ></app-footer>
                </footer>
            </div>
        }
    }
}
