import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { DataTerminalError, TimeModeEntry } from '@data-terminal/shared-models';
import { GLOBAL_SPINNER_HTTP_CONTEXT } from '@data-terminal/utils';

@Injectable({
    providedIn: 'root',
})
export class PureTimeApiService {
    readonly #httpClient = inject(HttpClient);
    readonly #backendUrl = inject(BACKEND_URL);
    readonly #orgRequestPrefix = inject(ORGANIZATION_REQUEST_PREFIX);
    readonly #ccAuthService = inject(CCAuthService);

    readonly #getTimeEntriesUrl = (shiftStart: number, deviceId: string): string =>
        `${this.#backendUrl}${this.#orgRequestPrefix}timemode/getByUser/${
            this.#ccAuthService.getCurrentUser()?.email
        }/${shiftStart}/${deviceId}`;

    readonly #getUnsubmittedTimeEntriesUrl = (): string =>
        `${this.#backendUrl}${this.#orgRequestPrefix}timemode/getUnsubmittedEntriesByUser/${this.#ccAuthService.getCurrentUser()?.email}`;

    readonly #updateEntriesUrl = (shiftStart: number, deviceId: string): string =>
        `${this.#backendUrl}${this.#orgRequestPrefix}timemode/updateEntrys/${
            this.#ccAuthService.getCurrentUser()?.email
        }/${shiftStart}/${deviceId}`;

    readonly #deleteEntryUrl = (shiftStart: number, deviceId: string): string =>
        `${this.#backendUrl}${this.#orgRequestPrefix}timemode/deleteEntry/${
            this.#ccAuthService.getCurrentUser()?.email
        }/${shiftStart}/${deviceId}`;

    readonly #startSubmittingUrl = (shiftStart: number, deviceId: string): string =>
        `${this.#backendUrl}${this.#orgRequestPrefix}timemode/startSubmittingEntries/${
            this.#ccAuthService.getCurrentUser()?.email
        }/${shiftStart}/${deviceId}`;

    public getTimeEntries(shiftStart: number, deviceId: string): Observable<TimeModeEntry[]> {
        return this.#httpClient.get<TimeModeEntry[]>(this.#getTimeEntriesUrl(shiftStart, deviceId));
    }

    public getUnsubmittedTimeEntries(): Observable<TimeModeEntry[]> {
        return this.#httpClient.get<TimeModeEntry[]>(this.#getUnsubmittedTimeEntriesUrl());
    }

    public updateEntries(
        entries: TimeModeEntry[],
        shiftStart: number,
        deviceId: string
    ): Observable<TimeModeEntry[] | DataTerminalError> {
        return this.#httpClient.post<TimeModeEntry[]>(this.#updateEntriesUrl(shiftStart, deviceId), entries, {
            context: GLOBAL_SPINNER_HTTP_CONTEXT,
        });
    }

    public deleteEntry(entry: TimeModeEntry, shiftStart: number, deviceId: string): Observable<TimeModeEntry[]> {
        return this.#httpClient.delete<TimeModeEntry[]>(this.#deleteEntryUrl(shiftStart, deviceId), {
            body: entry,
            context: GLOBAL_SPINNER_HTTP_CONTEXT,
        });
    }

    public startSubmittingEntries(shiftStart: number, deviceId: string): Observable<TimeModeEntry[]> {
        return this.#httpClient.post<TimeModeEntry[]>(this.#startSubmittingUrl(shiftStart, deviceId), null);
    }
}
