<div class="hdmui hdmui-m-0 wrapper hdmui-container request-delivery-dialog">
    <header>
        <h2>{{ 'DC.REQUEST_DELIVERY_DIALOG.TITLE' | translate }}</h2>
    </header>

    <section>
        @if (this.data.deliveryOption === RequestDeliveryItemType.SEMI_FINISHED_GOODS_AND_TOOLS) {
            <div class="request-delivery-dialog__delivery-option-section hdmui-content">
                <mat-icon svgIcon="hdmui:palletHigh"></mat-icon>
                <mat-icon svgIcon="hdmui:wrench"></mat-icon>
                <div class="request-delivery-dialog__delivery-option-section--text-section">
                    <span class="request-delivery-dialog__delivery-option-section--text-small">{{
                        'DC.REQUEST_DELIVERY_DIALOG.ITEM' | translate
                    }}</span>
                    <span
                        >{{ 'DC.REQUEST_DELIVERY_DIALOG.SEMI-FINISHED_GOODS' | translate }} /
                        {{ '*.tools' | translate }}</span
                    >
                </div>
            </div>
        } @else {
            <div class="request-delivery-dialog__delivery-option-section hdmui-content">
                <mat-icon
                    [svgIcon]="
                        this.data.deliveryOption === RequestDeliveryItemType.SEMI_FINISHED_GOODS
                            ? 'hdmui:palletHigh'
                            : 'hdmui:wrench'
                    "
                ></mat-icon>
                <div class="request-delivery-dialog__delivery-option-section--text-section">
                    <span class="request-delivery-dialog__delivery-option-section--text-small">{{
                        'DC.REQUEST_DELIVERY_DIALOG.ITEM' | translate
                    }}</span>
                    <span>{{
                        this.data.deliveryOption === RequestDeliveryItemType.SEMI_FINISHED_GOODS
                            ? ('DC.REQUEST_DELIVERY_DIALOG.SEMI-FINISHED_GOODS' | translate)
                            : ('*.tools' | translate)
                    }}</span>
                </div>
            </div>
        }

        <data-terminal-request-delivery-form
            [requestDeliveryForm]="requestDeliveryForm"
            [allOrgEmployees]="employees$ | async"
        ></data-terminal-request-delivery-form>
    </section>

    <mat-divider></mat-divider>

    <footer>
        <button mat-stroked-button (click)="cancel()">
            {{ '*.action.cancel' | translate }}
        </button>
        <button mat-flat-button (click)="requestDelivery()" [disabled]="!requestDeliveryForm.valid">
            {{ '*.request' | translate }}
        </button>
    </footer>
</div>
